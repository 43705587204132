const SEVERITY = {
    Message: 'message',
    Warning: 'warning',
    Error: 'error'
};

const loggers = {
    Console: 'console'
};

const trackers = {
    NewRelic: 'newrelic'
};

let currentSetup = [];
let valuesApplied = [];

/**
 * @description Function to set console logger and newrelic tracker
 * @param {*} trackers trackers to be set for logging
 */
const setup = (trackers) => {
    if (currentSetup.length !== 2) {
        currentSetup = [loggers.Console, trackers.NewRelic];
    }
};

/**
 * @description Function to add a new entry into valuesApplied List and applyNewrelic if currentSetup has Newrelic tracker
 * @param {*} key Add key for a new entry into values applied List
 * @param {*} value  Add value for the new entry in values applied List
 */
const apply = (key, value) => {
    valuesApplied[key] = value;

    if (currentSetup.some((l) => l === trackers.NewRelic)) {
        applyNewRelic(key, value);
    }
};

/**
 * @description Function to set key value params as Custom Attribute for new relic window
 * @param {*} key
 * @param {*} value
 */
const applyNewRelic = (key, value) => {
    if (window && window.newrelic) {
        window.newrelic.setCustomAttribute(key, value || '');
    }
};

/**
 * @description Function to set console logging or new relic logging 
 * @param {*} severity 
 * @param {*} message
 * @param {*} obj
 */
const action = (severity, message, obj) => {
    if (currentSetup.some((l) => l === loggers.Console)) {
        actionConsole(severity, message);
    }

    if (currentSetup.some((l) => l === trackers.NewRelic)) {
        actionNewRelic(severity, message, obj);
    }
};

/**
 * @description Function actionConsole checks logging severity and sets console logging accordingly
 * @param {*} severity
 * @param {*} message
 * @param {*} obj
 */
const actionConsole = (severity, message, obj) => {
    switch ((severity || SEVERITY.Message).toLowerCase()) {
        case SEVERITY.Error:
            console.error(message, obj);
            break;
        case SEVERITY.Warning:
            console.warn(message, obj);
            break;
        case SEVERITY.Message:
        default:
            console.log(message, obj);
            break;
    }
};

/**
 * @description Function actionNewRelic checks logging severity and sets newrelic logging accordingly
 * @param {*} severity
 * @param {*} message
 * @param {*} obj
 */
const actionNewRelic = (severity, message, obj) => {
    if (window && window.newrelic) {
        window.newrelic.addPageAction(message, obj);
    }
};

/**
 * @description Set Logger attributes to Driscolls Utility Logger
 * @param {*} severity
 * @param {*} message
 * @param {*} obj
 */
const DuLogger = {
    Setup: setup,
    Apply: apply,
    Action: action,
    log: (message, obj) => {
        action(SEVERITY.Message, message, obj);
    },
    warn: (message, obj) => {
        action(SEVERITY.Warn, message, obj);
    },
    error: (message, obj) => {
        action(SEVERITY.Error, message, obj);
    },
    SEVERITY: SEVERITY,
    Loggers: loggers,
    Trackers: trackers
};

export default DuLogger;
